<template>
  <div class="instance">
    <melon-header></melon-header>
    <img src="'https://static.livemew.com/livemew/image/banner.jpg'" alt="" class="banner" />
    <div class="nav-title">
      首页>最新动态<span class="now">>关于红包提现协议更新</span>
    </div>
    <div class="content">
      <div class="content-title">关于红包提现协议更新</div>
      <div class="content-date">2022-11-10 10:32</div>
      <div class="content-main"></div>

      <div class="float-a"></div>
    </div>
    <about-us></about-us>
    <melon-footer></melon-footer>
  </div>
</template>

<script>
import MelonHeader from '@/components/layout/MelonHeader.vue';
import MelonFooter from '@/components/layout/MelonFooter.vue';
import AboutUs from '@/views/home/components/AboutUs.vue';

export default {
  name: 'instance',
  components: {
    MelonHeader,
    MelonFooter,
    AboutUs,
  },
};
</script>

<style lang="less" scoped>
.instance {
  background: #f3f6fa;

  .banner {
    display: block;
    width: 100vw;
    // height: 148px;
  }
  .nav-title {
    width: 840px;
    margin: 0 auto;
    margin-top: 24px;
    font-size: 24px;
    color: rgba(0, 0, 0, 0.4);
    .now {
      color: rgba(0, 0, 0);
    }
  }
  .content {
    margin: 24px auto;
    width: 840px;
    min-height: 1333px;
    background: #ffffff;
    padding: 24px;
    box-sizing: border-box;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: center;
    position: relative;
    .float-a {
      width: 264px;
      height: 275px;
      background: #fff;
      position: absolute;
      top: 0;
      right: -24px;
      transform: translateX(100%);
    }
    .content-title {
      color: #333333;
      font-size: 36px;
      text-align: center;
    }
    .content-date {
      color: rgba(0, 0, 0, 0.25);
      font-size: 20px;
    }
    .content-main {
      margin-top: 36px;
      width: 792px;
      min-height: 1129px;
      background: #a0cdff;
    }
  }
}
</style>
